<template>
  <div class="unionApply">
    <PageNav title="申请加入产业联盟" />
    <div class="form-wrapper">
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <van-field
          v-model="formData.company"
          name="company"
          label="企业名称"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写企业名称' }]"
        />
        <van-field
          v-model="formData.officeAddress"
          name="officeAddress"
          label="办公地址"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写办公地址' }]"
        />
        <van-field
          v-model="formData.legalPerson"
          name="legalPerson"
          label="企业法人"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写企业法人' }]"
        />
        <van-field name="companyType" label="申请主体性质">
          <template #input>
            <van-radio-group direction="horizontal" v-model="formData.companyType">
              <van-radio name="1">总公司</van-radio>
              <van-radio name="2">分公司</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="formData.registeredCapital"
          name="registeredCapital"
          label="注册资本"
          placeholder="请输入"
          type="number"
        >
          <span slot="extra">万</span>
        </van-field>
        <van-field
          v-model="formData.brand"
          name="brand"
          label="品牌"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写品牌' }]"
        />
        <van-field
          v-model="formData.turnoverAvg"
          name="turnoverAvg"
          label="近三年平均营业额"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写近三年平均营业额' }]"
        />
        <van-field
          v-model="formData.mainProducts"
          name="mainProducts"
          label="主营产品"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写主营产品' }]"
        />
        <van-field name="trademarkImg" label="企业注册商标">
          <template #input>
            <van-uploader v-model="uploadList" :after-read="afterRead" :max-count="1" :max-size="5 * 1024 * 1024" />
          </template>
        </van-field>
        <van-field
          v-model="formData.applyPerson"
          name="applyPerson"
          label="申请人"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写申请人' }]"
        />
        <van-field
          v-model="formData.applyPhone"
          name="applyPhone"
          label="手机号码"
          placeholder="请输入"
          type="tel"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        />
        <van-field
          v-model="formData.applyEmail"
          name="applyEmail"
          label="电子邮箱"
          placeholder="请输入"
        />
        <van-field
          v-model="formData.applyReason"
          name="applyReason"
          label="申请理由"
          rows="2"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请填写申请理由，最多500个字"
          show-word-limit
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field, Form, RadioGroup, Radio, Uploader, Toast } from 'vant';
import axios from 'axios';
import Fetch from '../common/fetch';
import User from '../common/user';
import { HOST } from '../common/const';
import { UploadCompanyLogo, ApplyJoinUnion } from '../common/interface';

import PageNav from '@/components/PageNav';

Vue.use(Field);
Vue.use(Form);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);

export default {
  name: "UnionApply",
  components: { PageNav },
  data() {
    return {
      formData: {
        company: '',
        officeAddress: '',
        legalPerson: '',
        companyType: '1',
        registeredCapital: '',
        brand: '',
        turnoverAvg: '',
        mainProducts: '',
        applyPerson: '',
        applyPhone: '',
        applyEmail: '',
        applyReason: '',
      },
      uploadList: [],
      logoUrlKey: '',
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    afterRead(file) {
      const header = {
        sourceFlag: 'web',
        channelCode: '10001',
        appOwnership: 'EBei',
      };

      const user = User.getInfo() || {};
      const { token, uuid } = user;
      if (token) {
        header.token = token;
      }
      if (uuid) {
        header.uuid = uuid;
      }

      const imageUploadAction = `${HOST}${UploadCompanyLogo}`;

      const filedata = new FormData();
      filedata.append('file', file.file);

      axios
      .post(imageUploadAction, filedata, {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data"
        },
      })
      .then(res => {
        if (res.data.code == 1000) { // 上传成功
          const data = res.data.data;
          this.logoUrlKey = data.fileKey;
        } else {
          Toast('上传失败请重试');
        }
      });
    },

    onSubmit(values) {
      const params = {
        ...values,
        trademarkImg: this.logoUrlKey,
      };

      Fetch.post(ApplyJoinUnion, params).then(() => {
        Toast.success('申请提交成功,请留意站内消息');
        this.$router.go(-1);
      }).catch((err) => {
        Toast(err.msg || '请求失败');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .form-wrapper {
    position: relative;
    padding: 10px 5px 20px;
    background: #fff;
  }
</style>